import { defineAsyncComponent } from 'vue'
import Loading from './DropLoading.vue'

export const spacesneks = defineAsyncComponent({
  loader: () => import('./spacesneks/Home.vue'),
  loadingComponent: Loading
})

export const keycards = defineAsyncComponent({
  loader: () => import('./keycards/Home.vue'),
  loadingComponent: Loading
})
