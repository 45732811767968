<template>
  <component :is="CustomViews[drop.slug]" />
</template>

<script setup>
import { inject } from 'vue'

// Contains async component for each drop, which loads the full UI
import * as CustomViews from '@/components/custom'

const drop = inject('drop')
</script>